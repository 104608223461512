'use client';

import { useMemo, useState } from 'react';
import { MezzoforteProvider, useToast } from '@mezzoforte/forge';
import { PrismicProvider } from '@prismicio/react';
import {
  DefaultError,
  DehydratedState,
  HydrationBoundary,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PrismicPreview } from '@prismicio/next';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { IconContext } from '@mezzoforte/forge-icons';
import { isAxiosError } from 'axios';
import { ShowResponsive } from '@/components/Responsive/ShowResponsive';
import { HermesClientProvider } from '@/context/hermesContext';
import { useGtm } from '@/hooks/useGtm';
import { ExternalLinkComponent, InternalLinkComponent } from '@/prismic/linkComponents';
import richTextComponents from '@/prismic/richTextComponents';
import { DialogProvider } from '@/context/DialogProvider';
import { linkResolver, repositoryName } from '../../prismicio';

function isServiceUnavailableError(error: unknown) {
  return isAxiosError(error) && error.response?.status === 503;
}

function isTooManyRequestsError(error: unknown) {
  return isAxiosError(error) && error.response?.status === 429;
}

export default function Providers({
  queryClientState,
  children,
}: React.PropsWithChildren<{
  queryClientState: DehydratedState;
}>) {
  useGtm();
  const { playToast } = useToast();

  const [queryClient] = useState(() => {
    const onError = (error: DefaultError) => {
      // Display toast about maintenance break.
      if (isServiceUnavailableError(error)) {
        playToast(
          'Pieni hetki, huollamme sivustoa',
          'Huutokaupat.com on pois käytöstä huoltotöiden takia. Pyrimme saamaan palvelun käyttöön mahdollisimman pian.',
          {
            toastId: 'service-unavailable-error',
            variant: 'danger',
            closeManually: true,
          }
        );
      }

      if (isTooManyRequestsError(error)) {
        playToast('Virhe', 'Liikaa pyyntöjä. Odota hetki ja yritä sen jälkeen uudestaan.', {
          toastId: 'too-many-requests-error',
          variant: 'danger',
          closeManually: true,
        });
      }
    };

    return new QueryClient({
      queryCache: new QueryCache({ onError }),
      mutationCache: new MutationCache({ onError }),
      defaultOptions: {
        queries: {
          retry: (failureCount, error) => {
            // Avoid retrying during maintenance break.
            if (isServiceUnavailableError(error)) {
              return false;
            }

            return failureCount < 3;
          },
        },
      },
    });
  });

  const emotionCache = createCache({
    key: 'hk',
    stylisPlugins: [],
  });

  const iconContextValue = useMemo(() => ({ size: 24 }), []);

  return (
    <MezzoforteProvider brand="huutokaupat">
      <IconContext.Provider value={iconContextValue}>
        <CacheProvider value={emotionCache}>
          <PrismicProvider
            linkResolver={linkResolver}
            externalLinkComponent={ExternalLinkComponent}
            internalLinkComponent={InternalLinkComponent}
            richTextComponents={richTextComponents}
          >
            <PrismicPreview repositoryName={repositoryName}>
              <QueryClientProvider client={queryClient}>
                <HydrationBoundary state={queryClientState}>
                  <HermesClientProvider>
                    <DialogProvider>
                      {children}
                      <ShowResponsive lg>
                        <ReactQueryDevtools buttonPosition="bottom-left" />
                      </ShowResponsive>
                    </DialogProvider>
                  </HermesClientProvider>
                </HydrationBoundary>
              </QueryClientProvider>
            </PrismicPreview>
          </PrismicProvider>
        </CacheProvider>
      </IconContext.Provider>
    </MezzoforteProvider>
  );
}
